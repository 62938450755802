import { Col, Container, Jumbotron, Row } from "react-bootstrap"
import LookupForm from "./components/LookupForm"
import Footer from "./components/Footer"
import "./style/style.scss"
import { Header } from "./components/Header"
import { useState } from "react"

function App() {
	const [showContactUsModal, setShowContactUsModal] = useState(false)

	return (
		<div className="App">
			<Header />
			<Container className="justify-content-center">
				<Row>
					<Col>
						<Jumbotron>
							<h1>Find your My Clinical Outcomes website</h1>
						</Jumbotron>
						<hr />
						<section>
							<p>
								If you have been asked to register on the My
								Clinical Outcomes website you will find the
								correct website address in the documentation
								given or sent to you by your hospital.
							</p>
							<p>
								Alternatively you can enter your treatment
								information below and we'll try to look up the
								correct website address.
							</p>
						</section>
						<section>
							<LookupForm
								showContactUsModal={showContactUsModal}
								setShowContactUsModal={setShowContactUsModal}
							/>
						</section>
					</Col>
				</Row>
			</Container>
			<Footer setShowContactUsModal={setShowContactUsModal} />
		</div>
	)
}

export default App
