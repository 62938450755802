import { Button, Container } from "react-bootstrap"

function Footer({
	setShowContactUsModal
}: {
	setShowContactUsModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
	return (
		<footer>
			<Container fluid className="text-center">
				<p className="text-uppercase">
					FLOOR 37, 1 CANADA SQUARE, CANARY WHARF, LONDON, E14 5AA
					Company Number: 07557993
				</p>
				<a
					href="mailto:info@myclinicaloutcomes.com"
					className="contact-link"
				>
					info@myclinicaloutcomes.com
				</a>
				<ul className="list-inline">
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/privacy-policy">
							Privacy Policy
						</a>
					</li>
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/cookie-policy">
							Cookie Policy
						</a>
					</li>
					<li className="list-inline-item">
						<a href="http://www.myclinicaloutcomes.com/content/terms-of-service">
							Terms of Service
						</a>
					</li>
					<li className="list-inline-item">
						<Button
							variant="link"
							onClick={() => setShowContactUsModal(true)}
							className="mco-start-button-as-footer-link"
						>
							Contact Us
						</Button>
					</li>
				</ul>
			</Container>
		</footer>
	)
}

export default Footer
