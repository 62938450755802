import { Navbar } from "react-bootstrap"
import logo from "../images/MCO-Logo-White-Text.png"
import React from "react"

export function Header() {
	return (
		<header className="App-header">
			<Navbar bg="dark">
				<div className="text-center w-100">
					<Navbar.Brand href="https://www.myclinicaloutcomes.com">
						<img src={logo} alt="MCO Corporate Page" />
					</Navbar.Brand>
				</div>
				;
			</Navbar>
		</header>
	)
}
