import axios from "axios"
import { useState } from "react"
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { getApiUrl } from "./LookupForm"

export function ContactUsModal({
	show,
	setShow,
	treatmentCenter
}: {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	treatmentCenter: string
}) {
	const labelCol = 4
	const controlCol = 12 - labelCol
	const [error, setError] = useState(false)

	const { register, handleSubmit } = useForm()

	const onSubmit = (data: any) => {
		axios
			.put(getApiUrl("api/start/support"), data)
			.then(() => {
				setShow(false)
				setError(false)
			})
			.catch(() => {
				setError(true)
			})
	}

	return (
		<Modal
			animation={false}
			id="contact-us"
			centered={true}
			aria-labelledby="modalHeader"
			show={show}
			onHide={() => setShow(false)}
			size="lg"
		>
			<Modal.Header
				data-modal-header-for="contact-us"
				className="justify-content-start"
				closeButton={true}
			>
				{/* <MCOLogo className="mco-modal-logo" /> */}
				<div id="modalHeader">Contact Us</div>
			</Modal.Header>
			<Modal.Body data-modal-body-for="contact-us">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group as={Row}>
						<Form.Label column sm={labelCol}>
							Name
						</Form.Label>
						<Col sm={controlCol}>
							<Form.Control {...register("name")} />
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={labelCol}>
							Email
						</Form.Label>
						<Col sm={controlCol}>
							<Form.Control type="email" {...register("email")} />
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={labelCol}>
							Treatment Centre
						</Form.Label>
						<Col sm={controlCol}>
							<Form.Control
								{...register("treatmentCenter", {
									value: treatmentCenter
								})}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={labelCol}>
							Condition
						</Form.Label>
						<Col sm={controlCol}>
							<Form.Control
								{...register("condition", { value: "" })}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={labelCol}>
							Message
						</Form.Label>
						<Col sm={controlCol}>
							<textarea
								className="form-control"
								{...register("message")}
							/>
						</Col>
					</Form.Group>
					<Form.Group>
						<Form.Row>
							<Col className="d-flex justify-content-center">
								<Button type="submit">Send</Button>
							</Col>
						</Form.Row>
					</Form.Group>
					{error && (
						<Form.Group>
							<Form.Row>
								<Col className="d-flex justify-content-center">
									<Alert variant="danger">
										Something has gone wrong. Please try
										again or contact us at{" "}
										<a href="mailto:support@myclinicaloutcomes.co.uk">
											support@myclinicaloutcomes.co.uk
										</a>
									</Alert>
								</Col>
							</Form.Row>
						</Form.Group>
					)}
				</Form>
			</Modal.Body>
		</Modal>
	)
}
